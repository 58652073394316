/*
 * Misc.
 */

[v-cloak] {
    display: none;
}

#login-container .login-title {
    padding: 20px 10px;
    background: #444444;
    background: url('../img/template/ie8_opacity_dark_60.png') repeat;
    background: rgba(204, 153, 51, 0.8);
}

.document-preview {
    background: #ccc;
    width: 100%;
    height: 50vh;
}
.modal-preview-document,
.modal-verify-document {
    .modal-content {
        border-radius: 8px !important;
        min-height: 90vh;
        max-height: 90vh;
        display: flex;
        flex-direction: column;
        border: none;

        .modal-header {
            flex-shrink: 1;
            border-bottom: none;
            min-height: 70px;

            .modal-title {
                //color: $brand-primary;
                font-weight: 400;
                font-size: 20px;
                margin-top: 10px;
            }
            .close {
                font-size: 28px;
                margin-top: 6px;
            }
        }
        .modal-body {
            flex-grow: 1; // take up space within modal content

            display: flex;
            flex-direction: column;

            &.document {
                background-color:rgb(82, 86, 89);
                overflow-y: scroll;
                overflow-x: hidden;

                .document-container {
                    display: block;
                    flex-grow: 0;

                    .canvas-container {
                        //position: unset!important;
                        //margin-bottom: 10px;
                        //margin-top: 10px;
                        margin: 5px;
                        canvas {
                            //position: unset!important;
                            margin: 5px;
                        }
                    }
                }
            }

            .flex-grow-1 {
                flex-grow: 1;
                display: flex;
                flex-direction: column;

                .well {
                    height: 100%;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    justify-content: center;
                    border: 0px;
                    background-color: transparent;

                    .fa-spinner {
                        color: white;
                        font-size: 60px;
                    }
                }
            }
            .flex-shrink-1 { flex-shrink: 1; }
            .form-control { height: 46px; }

            .preview-container {
                position: relative;
                overflow: hidden;
                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                }
            }
        }
        .modal-footer {
            flex-shrink: 1;
            border-top: none;
            // padding-left: 0;
            // padding-right: 0;
            padding-bottom: 20px;

            .select2 { text-align: left; }
        }
    }
    @media (min-width: 768px) {
        &.modal-clean .modal-content .modal-body {
            padding: 20px;
        }
    }
}
